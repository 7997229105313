<template>
  <div id="xiaopingguo_vote" :style="`background-color:${votebgcolor}`">
    <template v-if="loaded">
      <div class="img">
        <img :src="`${voteheader}?t=${time}`" width="100%" />
      </div>
      <div class="weui-footer op">
        <p class="weui-footer__links">
          <a
            href="javascript:;"
            class="weui-footer__link open-popup"
            data-target="#toupiao"
            style="color:#fff;"
          >投票规则</a>
          <a
            href="javascript:;"
            class="weui-footer__link open-popup"
            data-target="#guicheng"
            style="color:#fff;"
          >竞赛规程</a>
        </p>
      </div>
      <template v-if="votelist.length>0">
        <div class="weui-search-bar" id="searchBar">
          <form class="weui-search-bar__form voteForm" style="background:none;">
            <div class="weui-search-bar__box">
              <i class="weui-icon-search"></i>
              <input
                type="search"
                class="weui-search-bar__input"
                name="name"
                id="searchInput"
                v-model="name"
                placeholder="输入队伍名称"
                required
              />
              <a href="javascript:" class="weui-icon-clear" id="searchClear" @click="name=''"></a>
            </div>
            <label class="weui-search-bar__label" id="searchText">
              <i class="weui-icon-search"></i>
              <span>输入队伍名称</span>
            </label>
          </form>
          <a
            href="javascript:"
            class="weui-search-bar__cancel-btn"
            id="searchCancel"
            @click="name=''"
          >取消</a>
        </div>
        <div
          v-if="showlist.length>0"
          class="weui-panel weui-panel_access"
          :style="`background-color:${votebgcolor}`"
        >
          <div
            class="weui-panel__hd"
            style="color:#fff;font-size: 1.4em;"
          >当前参与投票的队伍（{{votelist.length}}支）,当前总票数：{{allzancount}}</div>
          <div class="weui-panel__bd">
            <template v-if="searched">
              <a
                v-for="item in showlist"
                :key="item._id"
                :href="'/voteshare/'+$route.params.channel+'/'+item._id"
              >
                <div class="weui-media-box weui-media-box_text">
                  <div style="width:100%;height:200px;position:relative;">
                    <img
                      class="lazy"
                      :data-src="item.cover"
                      style="width:100%;height:200px;border-radius:10px;"
                    />
                    <img
                      src="https://scdn.xidong360.com/images/play.png"
                      style="position: absolute;width:50px;height:50px;left:calc( (100% - 50px) / 2);top: 75px;"
                    />
                  </div>
                  <h4 class="weui-media-box__title" style="color:#333;">{{item.gname}}</h4>
                  <div class="weui-flex">
                    <div class="weui-flex__item">
                      <div class="placeholder">
                        <span class="piao">{{item.zancount}}</span>票
                      </div>
                    </div>
                    <div class="weui-flex__item">
                      <div class="placeholder">
                        <span class="ming">{{item.zancount?item.ranking:'-'}}</span>名
                      </div>
                    </div>
                    <div v-if="voting" class="weui-flex__item">
                      <div class="placeholder">
                        <a
                          :href="'/voteshare/'+$route.params.channel+'/'+item._id"
                          class="weui-btn weui-btn_plain-primary"
                        >给TA投票</a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </template>
            <template v-else>
              <a
                v-for="item in votelist"
                :key="item._id"
                :href="'/voteshare/'+$route.params.channel+'/'+item._id"
              >
                <div class="weui-media-box weui-media-box_text">
                  <div style="width:100%;height:200px;position:relative;">
                    <img
                      class="lazy"
                      :data-src="item.cover"
                      style="width:100%;height:200px;border-radius:10px;"
                    />
                    <img
                      src="https://scdn.xidong360.com/images/play.png"
                      style="position: absolute;width:50px;height:50px;left:calc( (100% - 50px) / 2);top: 75px;"
                    />
                  </div>
                  <h4 class="weui-media-box__title" style="color:#333;">{{item.gname}}</h4>
                  <div class="weui-flex">
                    <div class="weui-flex__item">
                      <div class="placeholder">
                        <span class="piao">{{item.zancount}}</span>票
                      </div>
                    </div>
                    <div class="weui-flex__item">
                      <div class="placeholder">
                        <span class="ming">{{item.zancount?item.ranking:'-'}}</span>名
                      </div>
                    </div>
                    <div v-if="voting" class="weui-flex__item">
                      <div class="placeholder">
                        <a
                          :href="'/voteshare/'+$route.params.channel+'/'+item._id"
                          class="weui-btn weui-btn_plain-primary"
                        >给TA投票</a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </template>
          </div>
        </div>
        <div v-else class="tips">
          <p>没有符合条件的数据。</p>
        </div>
      </template>
      <div v-else class="tips">
        <p>暂无数据。</p>
      </div>
    </template>
    <div id="toupiao" class="weui-popup__container">
      <div class="weui-popup__overlay"></div>
      <div class="weui-popup__modal">
        <div class="weui-popup__modal">
          <div v-html="guize"></div>
          <a href="javascript:;" class="weui-btn weui-btn_primary close-popup">关闭</a>
        </div>
      </div>
    </div>
    <div id="guicheng" class="weui-popup__container">
      <div class="weui-popup__overlay"></div>
      <div class="weui-popup__modal">
        <div class="weui-popup__modal">
          <div v-html="guicheng"></div>
          <a href="javascript:;" class="weui-btn weui-btn_primary close-popup">关闭</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LazyLoad from "vanilla-lazyload";
import _ from "underscore";
export default {
  data() {
    return {
      loaded: false,
      votelist: [],
      voting: false,
      allzancount: 0,
      voteheader: "",
      votebgcolor: "#f5c3b3",
      guize: "",
      guicheng: "",
      name: "",
    };
  },
  computed: {
    time() {
      return this.$utils.dateTime(new Date());
    },
    showlist() {
      if (!this.name) return this.votelist;
      return _.filter(this.votelist, (n) => {
        return new RegExp("(" + this.name + ")").test(n.gname);
      });
    },
    searched() {
      return this.name !== "";
    },
  },
  watch: {
    showlist() {
      if (this.showlist.length > 0) {
        setTimeout(
          () =>
            new LazyLoad({
              elements_selector: ".lazy",
              load_delay: 300,
            }).update(),
          500
        );
      }
    },
  },
  methods: {
    getdata() {
      window.$.showLoading();
      this.$api
        .post("/wapi", {
          method: "vote/list",
          data: {
            gameId: this.$route.params.gameId,
            channel: this.$route.params.channel,
          },
        })
        .then((res) => {
          window.$.hideLoading();
          if (res.code !== 0) return;
          this.loaded = true;
          var title = `『${res.data.gamename}』，快来给你喜欢的队伍投票吧`;
          document.title = title;
          this.votelist = res.data.votelist;
          this.voting = res.data.voting;
          this.allzancount = res.data.allzancount;
          this.voteheader = res.data.voteheader;
          this.votebgcolor = res.data.votebgcolor;
          if (res.data.voteguize) this.guize = res.data.voteguize;
          if (res.data.voteguicheng) this.guicheng = res.data.voteguicheng;
          if (res.data.shareInfo) this.makeShare(res.data.shareInfo);
        })
        .catch((err) => {
          window.$.hideLoading();
          console.error(err);
        });
    },
    makeShare(shareInfo) {
      console.log(shareInfo);
      if (!shareInfo) return;
      this.$axios
        .get("https://tapi.xidong360.com/wxshare?t=" + Date.now())
        .then(({ data }) => {
          window.wx.config(data);
          window.wx.ready(function () {
            window.wx.updateAppMessageShareData({
              ..._.pick(shareInfo, ["title", "desc", "link", "imgUrl"]),
              success: function () {},
            });
            window.wx.updateTimelineShareData({
              ..._.pick(shareInfo, ["title", "link", "imgUrl"]),
              success: function () {},
            });
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.getdata();
  },
};
</script>
<style scoped>
@import "../assets/css/1.weui.css";
@import "../assets/css/2.jquery-weui.css";
.weui-panel__hd:after {
  border-bottom: none;
}

.weui-panel:before {
  border-top: none;
}

.weui-search-bar:before {
  border-top: none;
}

.weui-search-bar:after {
  border-bottom: none;
}

.weui-media-box__title {
  font-size: 1.6em;
}

.weui-search-bar__label {
  top: 3px;
}

.weui-media-box.weui-media-box_text {
  background-color: #fff;
  margin: 0 15px 20px 15px;
  border-radius: 10px;
}

.weui-search-bar__form:after {
  border: none;
}

.weui-media-box_text .weui-media-box__title {
  margin-bottom: 0;
}

.op {
  margin-bottom: 10px;
}

.op a {
  text-decoration: underline;
}

.header {
  padding-top: 10px;
}

.weui-popup__modal {
  background: #fff;
}

.second-title {
  text-align: center;
  font-size: 22px;
  color: #3cc51f;
  font-weight: 400;
  margin: 0 15%;
}
/* #xiaopingguo_vote {
  background-color: #f5c3b3;
} */
#xiaopingguo_vote .placeholder {
  height: 40px;
  line-height: 40px;
  font-size: 1.2em;
  color: #aaa;
}
#xiaopingguo_vote .placeholder span.piao {
  color: #e60012;
  font-weight: bold;
}
#xiaopingguo_vote .placeholder span.ming {
  color: #000;
}
#xiaopingguo_vote .placeholder a {
  font-size: 12px;
  border-radius: 20px;
  padding: 3px 0;
  color: #f5c3b3;
  border: 1px solid #f5c3b3;
}
#xiaopingguo_vote .tips {
  text-align: center;
  color: #fff;
  padding: 50px 0;
  font-size: 1.5em;
}
#xiaopingguo_vote #searchBar {
  background-color: #fff;
  padding: 5 15px;
  border-radius: 20px;
  margin: 0 10px;
}
</style>