<template name="voteshare">
  <div id="voteshare">
    <template v-if="loaded">
      <template v-if="vote">
        <video
          v-for="(item, index) in vote.videos"
          :key="index"
          id="vote_video"
          @play="playvideo"
          preload="none"
          :poster="`${vote.poster || game.voteposter}?${time}`"
          style="width: 100%"
          height="200"
          :src="item"
          controls="controls"
          controlslist="nodownload"
        ></video>
        <div class="weui-panel weui-panel_access vthead">
          <div class="weui-panel__bd">
            <div class="weui-media-box weui-media-box_text">
              <h4 class="weui-media-box__title ft18">{{ game.name }}</h4>
              <p v-if="vote.slogan" class="weui-media-box__desc">
                {{ vote.slogan }}
              </p>
              <p v-else class="weui-media-box__desc">
                无论你是跳舞’‘草根”还是跳舞“达人”，无论你跳的是健身秧歌还是啦啦操，只要你想跳，只要你想跟他人一较高下，那就赶快做好准备，{{
                  game.name
                }}，有你精彩！
              </p>
            </div>
          </div>
        </div>
        <div class="weui-cells" style="margin-top: 0">
          <div class="weui-cell">
            <div class="weui-cell__hd">
              <h4 style="font-weight: 500">{{ vote.ranks.vote.gname }}</h4>
            </div>
            <div class="weui-cell__bd"></div>
            <div class="weui-cell__ft">
              <span style="color: #000">{{
                vote.ranks.vote.zancount ? vote.ranks.vote.ranking : "-"
              }}</span
              >名
            </div>
          </div>
        </div>
        <div class="weui-flex">
          <div class="weui-flex__item">
            <p class="pzancount">
              <span style="color: #ef4248">{{ vote.ranks.vote.zancount }}</span
              >票
            </p>
            <div
              class="placeholder"
              style="padding: 10px 0; text-align: center"
            >
              <template v-if="voting">
                <a href="javascript:;" id="zan" @click="zan">
                  <img
                    src="https://scdn.xidong360.com/gcw/tp.gif"
                    style="height: 8rem; width: 8rem"
                  />
                </a>
              </template>
              <a v-else href="javascript:;">
                <img
                  src="https://scdn.xidong360.com/gcw/tpno.gif"
                  style="height: 8rem; width: 8rem"
                />
                <p style="text-align: center">投票未开启</p>
              </a>
            </div>
          </div>
        </div>
        <div class="weui-panel weui-panel_access">
          <div class="weui-panel__bd">
            <div class="weui-media-box weui-media-box_text">
              <h4 class="weui-media-box__title">当前排行榜位置</h4>
              <div class="weui-cells">
                <div
                  v-if="vote.ranks.lastvote"
                  class="weui-cell last tovote"
                  @click="$router.push(`/voteshare/${vote.ranks.lastvote._id}`)"
                >
                  <div class="weui-cell__hd">
                    {{ vote.ranks.lastvote.ranking }}
                  </div>
                  <div class="weui-cell__bd">
                    <p style="margin-left: 30px">
                      {{ vote.ranks.lastvote.gname }}
                    </p>
                  </div>
                  <div class="weui-cell__ft">
                    <span style="font-size: 1.2rem"
                      >{{ vote.ranks.lastvote.zancount }}票</span
                    >
                  </div>
                </div>
                <div v-if="vote.ranks.vote" class="weui-cell me">
                  <div class="weui-cell__hd">{{ vote.ranks.vote.ranking }}</div>
                  <div class="weui-cell__bd">
                    <p style="margin-left: 30px">{{ vote.ranks.vote.gname }}</p>
                  </div>
                  <div class="weui-cell__ft">
                    <span style="font-size: 1.2rem"
                      >{{ vote.ranks.vote.zancount }}票</span
                    >
                  </div>
                </div>
                <div
                  v-if="vote.ranks.nextvote"
                  class="weui-cell next tovote"
                  @click="$router.push(`/voteshare/${vote.ranks.nextvote._id}`)"
                >
                  <div class="weui-cell__hd">
                    {{ vote.ranks.nextvote.ranking }}
                  </div>
                  <div class="weui-cell__bd">
                    <p style="margin-left: 30px">
                      {{ vote.ranks.nextvote.gname }}
                    </p>
                  </div>
                  <div class="weui-cell__ft">
                    <span style="font-size: 1.2rem"
                      >{{ vote.ranks.nextvote.zancount }}票</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="weui-msg">
          <div class="weui-msg__text-area">
            <p class="weui-msg__desc">
              打开页面右上角"..."，点击“发送给朋友”或“分享到朋友圈”，邀请小伙伴来为TA投票吧。
            </p>
          </div>
        </div>
        <div class="weui-footer">
          <p class="weui-footer__links">
            <a
              v-if="channel"
              :href="`/votelist/${channel}/${vote.gameId}`"
              class="weui-footer__link"
              >查看全部队伍</a
            >
            <a
              v-else
              :href="`/votelist/${vote.gameId}`"
              class="weui-footer__link"
              >查看全部队伍</a
            >
          </p>
        </div>
      </template>
      <div v-else class="weui-msg">
        <div class="weui-msg__icon-area">
          <i class="weui-icon-warn weui-icon_msg-primary"></i>
        </div>
        <div class="weui-msg__text-area">
          <h2 class="weui-msg__title">投票不存在</h2>
          <p class="weui-msg__desc"></p>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import _ from "underscore";
export default {
  data() {
    return {
      vote: null,
      game: null,
      voting: false,
      loaded: false,
    };
  },
  computed: {
    time() {
      return this.$utils.dateTime(new Date(), "YYYY-MM-DD HH");
    },
    channel() {
      return this.$route.params.channel || "";
    },
  },
  watch: {
    "$route.params.voteId"() {
      this.getdata();
    },
  },
  methods: {
    getdata() {
      window.$.showLoading();
      this.$api
        .post("/wapi", {
          method: "vote/info",
          data: {
            voteId: this.$route.params.voteId,
            channel: this.$route.params.channel || "",
          },
        })
        .then((res) => {
          window.$.hideLoading();
          if (res.code !== 0) return;
          this.loaded = true;
          this.vote = res.data.vote;
          var title = `“${res.data.vote.gname}”正在参加『${res.data.game.name}』，来给TA投票吧`;
          document.title = title;
          this.game = res.data.game;
          this.voting = res.data.voting;
          if (res.data.shareInfo) this.makeShare(res.data.shareInfo);
        })
        .catch((err) => {
          window.$.hideLoading();
          console.error(err);
        });
    },
    zan() {
      window.$.showLoading();
      this.$api
        .post("/wapi", {
          method: "vote/zan",
          data: {
            voteId: this.$route.params.voteId,
            channel: this.$route.params.channel || "",
          },
        })
        .then((res) => {
          window.$.hideLoading();
          if (res.code !== 0) return window.$.alert(res.msg);
          let alertText = res.data.zanText;
          window.$.alert(alertText, "温馨提示");
          if (res.data.zaned) this.getdata();
        })
        .catch((err) => {
          window.$.hideLoading();
          console.error(err);
        });
    },
    playvideo() {
      this.$api
        .post("/wapi", {
          method: "vote/play",
          data: {
            voteId: this.$route.params.voteId,
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    makeShare(shareInfo) {
      console.log(shareInfo);
      this.$axios
        .get("https://tapi.xidong360.com/wxshare?t=" + Date.now())
        .then(({ data }) => {
          window.wx.config(data);
          window.wx.ready(function () {
            window.wx.showAllNonBaseMenuItem();
            if (shareInfo) {
              window.wx.updateAppMessageShareData({
                ..._.pick(shareInfo, ["title", "desc", "link", "imgUrl"]),
                success: function () {},
              });
              window.wx.updateTimelineShareData({
                ..._.pick(shareInfo, ["title", "link", "imgUrl"]),
                success: function () {},
              });
            } else {
              window.wx.hideAllNonBaseMenuItem();
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.getdata();
    window.$("body").css("background", "#f5f5f5");
  },
};
</script>
  <style scoped>
@import "../assets/css/1.weui.css";
@import "../assets/css/2.jquery-weui.css";
#voteshare .vthead {
  margin-top: 0;
  border-bottom: 1px solid #eeee;
}
#voteshare .ft18 {
  font-size: 1.8rem;
}
#voteshare .weui-media-box__desc {
  font-size: 1.2rem;
  color: #999;
  display: block;
}
#voteshare .pzancount {
  text-align: center;
  font-size: 1.5em;
  margin-top: 20px;
}
#voteshare .weui-footer {
  margin-bottom: 10px;
}
#voteshare .weui-msg {
  padding-top: 0;
  margin-bottom: 10px;
  margin-top: 10px;
}
#voteshare .weui-cell.me {
  font-weight: bold;
}
</style>