<template>
  <div class="page">
    <div class="header">
      <van-nav-bar
        :title="vote.gname"
        left-text="返回"
        left-arrow
        @click-left="onClickBack"
      ></van-nav-bar>
      <div class="rank-container">
        <van-grid :column-num="vote.votecert ? 4 : 3">
          <van-grid-item icon="user-o" :text="(vote.number || '-') + '号'" />
          <van-grid-item
            icon="good-job-o"
            :text="(vote.zancount || '-') + '票'"
          />
          <van-grid-item icon="medal-o" :text="(vote.rank || '-') + '名'" />
          <van-grid-item
            v-if="vote.votecert"
            icon="down"
            text="下载证书"
            @click="downloadVoteCert"
          />
        </van-grid>
      </div>
    </div>
    <div class="detail_main">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
        @play="onPlayerPlay"
      />
    </div>
    <div class="vote-op">
      <van-button type="primary" round large block @click="zan"
        >给TA投票</van-button
      >
    </div>
    <van-cell title="参赛宣言" icon="volume-o" />
    <div class="detail-info">
      <van-skeleton title avatar :row="30" :loading="loading">
        <div class="slogan-container">
          <article>
            <div v-html="vote.slogan"></div>
          </article>
        </div>
      </van-skeleton>
    </div>
    <van-cell title="活动规则" icon="info-o" />
    <div class="detail-info">
      <van-skeleton title avatar :row="30" :loading="loading">
        <div class="slogan-container">
          <article>
            <div v-html="vote.guize"></div>
          </article>
        </div>
      </van-skeleton>
    </div>
    <div class="tool-menu" v-if="vote.signable">
      <div class="menu" @click="gotosign">
        <img src="/images/sign.png" width="40" alt />
        <div class="info">立即报名</div>
      </div>
    </div>
    <van-dialog
      v-model="showcert"
      :show-confirm-button="false"
      show-cancel-button
      close-on-click-overlay
      cancel-button-text="关闭"
    >
      <div
        :style="`text-align: center;background:url(${cert}) center center / cover no-repeat;width:100%;height:100%;`"
      >
        <img :src="cert" style="height: 80vh" />
      </div>
      <p style="text-align: center">长按上方图片即可保存</p>
    </van-dialog>
    <SSYFooter />
  </div>
</template>
<script>
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import "videojs-contrib-hls";
import _ from "underscore";
import Vue from "vue";
import {
  Skeleton,
  Button,
  Grid,
  GridItem,
  Cell,
  CellGroup,
  Image,
  Dialog,
} from "vant";
Vue.use(Skeleton);
Vue.use(Button);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Image);
Vue.use(Dialog);
import VideoPlayer from "vue-video-player";
Vue.use(VideoPlayer);
import SSYFooter from "../../common/SSYFooter";

export default {
  name: "VoteDetail",
  data() {
    return {
      voteId: this.$route.params.id,
      loading: true,
      playerOptions: {
        aspectRatio: "16:9",
        fluid: true,
      },
      vote: {
        matchId: "",
        gname: "",
        number: "",
        zancount: 0,
        rank: 0,
        guize: "",
        signable: false,
        votecert: false,
      },
      showcert: false,
      cert: "",
    };
  },
  components: { SSYFooter },
  created() {
    this.getvoteinfo();
  },
  mounted() {},
  methods: {
    getvoteinfo() {
      this.$toast.loading({ message: "加载数据" });
      this.$post("/api/votedetail/getinfo", { voteId: this.voteId })
        .then((res) => {
          this.$toast.clear();
          console.log(res);
          if (res.code === 0) {
            this.makeShare(res.data.shareInfo);
            this.loading = false;
            if (res.data.vote && res.data.vote.matchId) {
              this.vote = res.data.vote;
              document.title = res.data.vote.gname;
              this.playerOptions = this.$makeVideoOptions(res.data.vote);
            } else {
              this.$dialog
                .alert({
                  title: "提示",
                  message: "投票信息无效",
                  confirmButtonText: "关闭",
                })
                .then(() => {
                  window.wx.closeWindow();
                });
            }
          } else {
            this.$toast.fail(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    onPlayerPlay() {
      this.$post("/api/votedetail/updateplaycount", { voteId: this.voteId })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    makeShare(shareInfo) {
      console.log(shareInfo);
      this.$axios
        .get("https://tapi.xidong360.com/wxshare?t=" + Date.now())
        .then(({ data }) => {
          window.wx.config(data);
          window.wx.ready(function () {
            window.wx.showAllNonBaseMenuItem();
            if (shareInfo) {
              window.wx.updateAppMessageShareData({
                ..._.pick(shareInfo, ["title", "desc", "link", "imgUrl"]),
                success: function () {},
              });
              window.wx.updateTimelineShareData({
                ..._.pick(shareInfo, ["title", "link", "imgUrl"]),
                success: function () {},
              });
            } else {
              window.wx.hideAllNonBaseMenuItem();
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    zan() {
      this.$toast.loading({ message: "" });
      this.$post("/api/votedetail/zan", { voteId: this.voteId })
        .then((res) => {
          console.log(res);
          this.$toast.clear();
          if (res.code === 0) {
            this.$toast.success(res.msg);
            let { votelottery, showvoteresult } = res.data;
            setTimeout(
              () => {
                if (votelottery || showvoteresult) {
                  return (window.location.href = "/voteresult/" + this.voteId);
                }
                this.getvoteinfo();
              },
              votelottery ? 1000 : 2000
            );
          } else {
            this.$toast.fail(res.msg);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    ///分享
    shareLink() {},
    ////返回上级
    onClickBack() {
      window.wx.showAllNonBaseMenuItem();
      this.$router.go(-1);
    },
    ///生成证书
    downloadVoteCert() {
      this.$toast.loading({ message: "" });
      this.$post("/api/votedetail/downloadVoteCert", { voteId: this.voteId })
        .then((res) => {
          console.log(res);
          this.$toast.clear();
          if (res.code === 0) {
            let { cert } = res.data;
            if (cert) {
              this.cert = cert;
              this.showcert = true;
            } else {
              this.$toast.fail("下载失败");
            }
          } else {
            this.$toast.fail(res.msg);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    ///报名
    gotosign() {
      this.$router.push({ path: "/voteadd/" + this.vote.matchId });
    },
  },
};
</script>
<style lang="less" scoped>
.header p {
  text-align: center;
  margin: 0;
  padding: 20px 0;
}
.vote-op {
  padding: 20px 10px;
}
.detail-info {
  padding: 5px;
  font-size: 14px;
}
.tool-menu {
  position: fixed;
  right: 0.5rem;
  bottom: 3rem;
  z-index: 100;
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      width: 4.5rem;
      height: 2.5rem;
      cursor: pointer;
    }
    .share {
      background: url(https://scdn.xidong360.com/vote2020/images/share.png)
        no-repeat;
      background-size: 100%;
    }
  }
}
.slogan-container {
  padding: 10px;
}
</style>