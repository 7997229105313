<template>
  <div class="page">
    <div class="header">
      <van-nav-bar
        :title="$route.query.type + ':' + $route.query.name"
        left-text="返回"
        left-arrow
        @click-left="onClickBack"
      ></van-nav-bar>
      <div class="rank-container">
        <van-grid :column-num="2">
          <van-grid-item icon="medal-o" :text="$route.query.type" />
          <van-grid-item icon="user-o" :text="$route.query.name" />
        </van-grid>
      </div>
    </div>
    <template v-if="!left">
      <van-cell title="说明" icon="info-o" />
      <div class="detail-info">
        <van-skeleton title avatar :row="30" :loading="loading">
          <div class="slogan-container">
            <article>
              <div v-html="guize"></div>
            </article>
          </div>
        </van-skeleton>
      </div>
    </template>
    <div class="vote-op" v-if="finished">
      <van-button v-if="left" type="primary" round large block @click="zan"
        >查看证书</van-button
      >
      <van-form v-else @submit="onSubmit">
        <van-field
          v-model="userinfo.name"
          name="name"
          label="姓名"
          placeholder="姓名"
          :rules="[{ required: true, message: '请填写姓名' }]"
        />
        <van-field
          v-model="userinfo.phone"
          name="phone"
          label="电话"
          placeholder="电话"
          :rules="[{ required: true, message: '请填写电话' }]"
        />
        <van-field
          v-model="userinfo.address"
          rows="3"
          autosize
          name="address"
          label="地址"
          type="textarea"
          placeholder="地址"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
    <SSYFooter />
  </div>
</template>
<script>
import _ from "underscore";
import Vue from "vue";
import {
  Skeleton,
  Button,
  Grid,
  GridItem,
  Cell,
  NavBar,
  Form,
  Field,
  ImagePreview,
} from "vant";
Vue.use(Skeleton);
Vue.use(Button);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Cell);
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
import SSYFooter from "../../common/SSYFooter";

export default {
  name: "VoteZhengshu",
  data() {
    return {
      loading: true,
      guize: "",
      zhengshu: "",
      left: false,
      finished: false,
      userinfo: {
        name: "",
        phone: "",
        address: "",
      },
    };
  },
  components: { SSYFooter },
  created() {},
  mounted() {
    this.getzhengshuinfo();
  },
  methods: {
    getzhengshuinfo() {
      this.$toast.loading({ message: "加载数据" });
      this.$post("/api/vote/getzhengshuinfo", {
        teamId: this.$route.params.id,
        type: this.$route.query.type,
        name: this.$route.query.name,
      })
        .then((res) => {
          this.$toast.clear();
          console.log(res);
          this.finished = true;
          if (res.code === 0) {
            this.guize = res.data.guize;
            document.title =
              res.data.gname +
              "-" +
              this.$route.query.type +
              ":" +
              this.$route.query.name;
            this.left = res.data.left;
            this.zhengshu = res.data.zhengshu;
            this.loading = false;
            this.makeShare(res.data.shareInfo);
          } else {
            this.$toast.fail(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    makeShare(shareInfo) {
      console.log(shareInfo);
      this.$axios
        .get("https://tapi.xidong360.com/wxshare?t=" + Date.now())
        .then(({ data }) => {
          window.wx.config(data);
          window.wx.ready(function () {
            window.wx.showAllNonBaseMenuItem();
            if (shareInfo) {
              window.wx.updateAppMessageShareData({
                ..._.pick(shareInfo, ["title", "desc", "link", "imgUrl"]),
                success: function () {},
              });
              window.wx.updateTimelineShareData({
                ..._.pick(shareInfo, ["title", "link", "imgUrl"]),
                success: function () {},
              });
            } else {
              window.wx.hideAllNonBaseMenuItem();
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    zan() {
      ImagePreview({
        images: [this.zhengshu],
        showIndex: false,
        closeable: true,
      });
    },
    onSubmit(values) {
      console.log("submit", values, this.userinfo);
      this.$toast.loading({ message: "" });
      this.$post("/api/vote/savezhengshuinfo", {
        teamId: this.$route.params.id,
        type: this.$route.query.type,
        name: this.$route.query.name,
        userinfo: this.userinfo,
      })
        .then((res) => {
          console.log(res);
          this.$toast.clear();
          if (res.code === 0) {
            this.left = res.data.left;
            this.zhengshu = res.data.zhengshu;
          } else {
            this.$toast.fail(res.msg);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    ////返回上级
    onClickBack() {
      window.wx.showAllNonBaseMenuItem();
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less">
.header p {
  text-align: center;
  margin: 0;
  padding: 20px 0;
}
.vote-op {
  padding: 20px 10px;
}
.detail-info {
  padding: 5px;
  font-size: 14px;
}
.van-nav-bar__right {
  right: 5px;
  top: 5px;
}
.tool-menu {
  position: fixed;
  right: 0.5rem;
  bottom: 3rem;
  z-index: 100;
  .menu {
    display: flex;
    flex-direction: column;
    div {
      width: 2.5rem;
      height: 2.5rem;
      cursor: pointer;
    }
    .share {
      background: url(https://scdn.xidong360.com/vote2020/images/share.png)
        no-repeat;
      background-size: 100%;
    }
  }
}
.slogan-container {
  padding: 10px;
}
</style>