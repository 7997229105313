<template>
  <div class="page">
    <div class="header">
      <van-image :src="match.cover" />
    </div>
    <div class="extra">
      <div class="messge"></div>
      <van-panel title="温馨提示" desc="感谢你的参与，请认真填写下列信息参与比赛（优先提供MP4格式的视频文件）。">
        <van-form>
          <van-field
            v-model="name"
            name="姓名"
            label="姓名"
            placeholder="姓名"
            :rules="[{ required: true, message: '请填写姓名' }]"
          />
          <van-field
            v-model="phone"
            name="手机号"
            label="手机号"
            placeholder="手机号"
            :rules="[{ required: true, message: '请填写手机号码' }]"
          />
          <van-field
            readonly
            clickable
            label="衣服尺码"
            :value="clothesSize"
            placeholder="选择衣服尺码"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" round position="bottom">
            <van-picker
              show-toolbar
              :columns="clothesSizeArry"
              @cancel="showPicker = false"
              @confirm="onConfirmClothesSize"
            />
          </van-popup>
          <van-field label="视频上传">
            <template #input>
              <div
                class="uploadBtn"
                v-if="fileList.length === 0"
                @click="$refs.uploadVoteVideo.click()"
              >
                <van-icon name="photograph" size="24" color="#dcdee0" />
              </div>
              <div v-else style="width: 100%;position:relative;">
                <video
                  :src="fileList[0].url"
                  :poster="fileList[0].poster"
                  style="width: 100%; height: 45vw"
                  controls
                  preload="none"
                />
                <div
                  @click="removeFromFileList"
                  style="position: absolute;right: -10px;top: -10px;z-index: 10;"
                >
                  <van-icon name="close" color="red" style="font-size:30px;" />
                </div>
              </div>
            </template>
          </van-field>
          <van-field
            v-model="slogan"
            rows="2"
            autosize
            label="参赛宣言"
            type="textarea"
            maxlength="50"
            placeholder="请输入参赛宣言"
            show-word-limit
          />
        </van-form>
        <div slot="footer">
          <van-button round block type="info" @click="onSubmit">提交</van-button>
        </div>
      </van-panel>
    </div>
    <input
      style="display: none"
      type="file"
      accept="video/mp4, video/quicktime, video/x-msvideo"
      ref="uploadVoteVideo"
      @change="change"
    />
    <SSYFooter />
  </div>
</template>
<script>
import Vue from "vue";
import { Button, Panel, Form, Image, Field, Icon, Picker } from "vant";
Vue.use(Button);
Vue.use(Form);
Vue.use(Image);
Vue.use(Panel);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Picker);
import SSYFooter from "../../common/SSYFooter";
import _ from "underscore";
export default {
  name: "result",
  data() {
    return {
      name: "",
      phone: "",
      slogan: "",
      fileList: [],
      shareInfo: null,
      clothesSize: "",
      showPicker: false,
      clothesSizeArry: [
        "S/165(男装)",
        "M/170(男装)",
        "L/175(男装)",
        "XL/180(男装)",
        "2XL/185(男装)",
        "3XL/190(男装)",
        "4XL/195(男装)",
        "M/165(女装)",
        "L/170(女装)",
        "XL/175(女装)",
        "2XL/180(女装)",
        "3XL/185(女装)"
      ],
      match: {}
    };
  },
  components: {
    SSYFooter
  },
  created() {
    this.makeShare();
    this.getdata();
  },
  methods: {
    onConfirmClothesSize(value) {
      this.clothesSize = value;
      this.showPicker = false;
    },
    getdata() {
      this.$toast.loading({ message: "加载数据" });
      this.$post("/api/game/getinfo", {
        matchId: this.$route.params.id
      })
        .then(res => {
          this.$toast.clear();
          console.log(res);
          if (res.code === 0) {
            if (res.data.match) {
              this.match = res.data.match;
              document.title = res.data.match.name;
            }
            this.makeShare(res.data.shareInfo);
          } else {
            this.$toast.fail(res.msg);
          }
        })
        .catch(err => {
          this.$toast.clear();
          console.error(err);
        });
    },
    makeShare(shareInfo) {
      this.$axios
        .get("https://tapi.xidong360.com/wxshare?t=" + Date.now())
        .then(({ data }) => {
          window.wx.config(data);
          window.wx.ready(function() {
            window.wx.showAllNonBaseMenuItem();
            if (shareInfo) {
              window.wx.updateAppMessageShareData({
                ..._.pick(shareInfo, ["title", "desc", "link", "imgUrl"]),
                success: function() {}
              });
              window.wx.updateTimelineShareData({
                ..._.pick(shareInfo, ["title", "link", "imgUrl"]),
                success: function() {}
              });
            } else {
              window.wx.hideAllNonBaseMenuItem();
            }
          });
        })
        .catch(err => {
          console.error(err);
        });
    },
    removeFromFileList() {
      this.fileList = [];
    },
    change(e) {
      let matchId = this.$route.params.id;
      let file = e.target.files[0];
      console.log(file);
      if (!file) return;
      this.$refs.uploadVoteVideo.value = null;
      let accept = "video/mp4,video/quicktime,video/x-msvideo".split(",");
      const isAccept = accept.indexOf(file.type) > -1;
      const isLt100M = file.size / 1024 / 1024 < 100;
      if (!isAccept) {
        return this.$toast.fail("上传的视频只能是 mp4/mov/avi 格式!");
      } else if (!isLt100M) {
        return this.$toast.fail("上传的视频最大不能超过100MB!");
      }
      let slicefiles = this.$utils.sliceFile(file);
      let faid = this.$utils.makeUUID();
      this.$toast.loading({ message: "上传中..." });
      this.$async.mapSeries(
        slicefiles,
        (slicefile, callback) => {
          let formData = new FormData();
          if (slicefile.chunks > 1) {
            let _file = file.slice(slicefile.from, slicefile.to);
            formData.append("file", _file);
          } else {
            formData.append("file", file);
          }
          formData.set("matchId", matchId);
          formData.set("faid", faid);
          formData.set("chunk", slicefile.chunk);
          formData.set("chunks", slicefile.chunks);
          formData.set("name", slicefile.name);
          this.$api
            .plupload(formData, "video")
            .then(res => {
              console.log(res);
              if (res.code === 0) {
                if (slicefile.chunk + 1 === slicefile.chunks) {
                  callback(null, res.data);
                } else {
                  callback(null, {});
                }
              } else {
                callback(res.msg);
              }
            })
            .catch(err => {
              console.error(err);
              callback(err);
            });
        },
        (err, results) => {
          this.$toast.clear();
          if (!err) {
            console.log(results);
            let videourl = results[results.length - 1].url;
            let videocover = results[results.length - 1].cover;
            let videoduration = results[results.length - 1].duration;
            if (videourl) {
              this.fileList = [
                { url: videourl, poster: videocover, duration: videoduration }
              ];
            } else {
              this.$toast.fail("上传失败");
            }
          } else {
            this.$toast.fail(err);
          }
        }
      );
    },
    onSubmit() {
      if (!this.name) return this.$toast.fail("姓名不能为空");
      if (!this.phone) return this.$toast.fail("手机号不能为空");
      if (!this.clothesSize) return this.$toast.fail("衣服尺码不能为空");
      if (this.fileList.length === 0) return this.$toast.fail("视频不能为空");
      if (!this.slogan) return this.$toast.fail("参赛宣言不能为空");
      this.$toast.loading({ message: "" });
      this.$api
        .post("/api/vote/add", {
          matchId: this.$route.params.id,
          name: this.name,
          phone: this.phone,
          clothessize: this.clothesSize,
          ...this.fileList[0],
          slogan: this.slogan
        })
        .then(res => {
          this.$toast.clear();
          if (res.code === 0) {
            this.$dialog
              .alert({
                title: "提示",
                message: "已提交成功,等待系统审核",
                confirmButtonText: "确定"
              })
              .then(() => {
                this.$router.push({
                  path: "/votedetail/" + res.data.voteId
                });
              });
          } else {
            this.$toast.fail(res.msg);
          }
        })
        .catch(err => {
          this.$toast.clear();
          console.error(err);
        });
    },
    ////返回上级
    onClickBack() {
      this.$router.go(-1);
      window.wx.showAllNonBaseMenuItem();
    }
  }
};
</script>
<style lang="less">
.status {
  text-align: center;
  padding: 30px 0 20px 0;
  .info {
    padding: 20px 10px;
  }
}
.footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  a {
    text-decoration: underline;
    margin: 0 3px;
    color: #25bf3a;
  }
}
.uploadBtn {
  background-color: #f2f2f2;
  width: 100%;
  height: 45vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>