import { render, staticRenderFns } from "./404.vue?vue&type=template&id=6aa1922d&scoped=true&"
var script = {}
import style0 from "./404.vue?vue&type=style&index=0&id=6aa1922d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.3_94906d065d09e1dfc0314e7d34126f20/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aa1922d",
  null
  
)

export default component.exports