import { render, staticRenderFns } from "./voteshare.vue?vue&type=template&id=f370f3f0&scoped=true&name=voteshare&"
import script from "./voteshare.vue?vue&type=script&lang=js&"
export * from "./voteshare.vue?vue&type=script&lang=js&"
import style0 from "./voteshare.vue?vue&type=style&index=0&id=f370f3f0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.3_94906d065d09e1dfc0314e7d34126f20/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f370f3f0",
  null
  
)

export default component.exports