import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import VoteList from '@/components/vote/list.vue'
import VoteDetail from '@/components/vote/detail.vue'
import VoteResult from '@/components/vote/result.vue'
import VoteZhengshu from '@/components/vote/zhengshu.vue'
import NotFound from '@/components/404.vue'
import LeaderBoards from '@/views/leaderboards.vue'
import LeaderBoards2 from '@/views/leaderboards2.vue'
import VoteShare from '@/views/voteshare.vue';
import VoteAdd from '@/components/vote/add.vue';

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      meta: { wid: false },
    },
    {
      path: '/vote/:id',
      name: 'VoteList',
      component: VoteList,
      meta: { wid: true },
    },
    {
      path: '/votedetail/:id',
      name: 'VoteDetail',
      component: VoteDetail,
      meta: { wid: true },
    },
    {
      path: '/voteresult/:id',
      name: 'VoteResult',
      component: VoteResult,
      meta: { wid: true },
    },
    {
      path: '/votezhengshu/:id',
      name: 'VoteZhengshu',
      component: VoteZhengshu,
      meta: { wid: true },
    },
    {
      path: '/votelist/:gameId',
      name: 'LeaderBoards',
      component: LeaderBoards,
      meta: { wid: true },
    },
    {
      path: '/voteshare/:voteId',
      name: 'VoteShare',
      component: VoteShare,
      meta: { wid: true },
    },
    {
      path: '/votelist/:channel/:gameId',
      name: 'LeaderBoards2',
      component: LeaderBoards2,
      meta: { wid: true },
    },
    {
      path: '/voteshare/:channel/:voteId',
      name: 'VoteShare',
      component: VoteShare,
      meta: { wid: true },
    },
    {
      path: '/voteadd/:id',
      name: 'VoteAdd',
      component: VoteAdd,
      meta: { wid: true },
    },
    {
      path: '/404',
      name: '404',
      component: NotFound,
      meta: { wid: false },
    },
    {
      path: '*',    // 此处需特别注意至于最底部
      redirect: '/404'
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition);
    return { x: 0, y: 0 };
  },
});

// 验证 wid
router.beforeEach((to, from, next) => {
  console.log(1, from);
  console.log(2, to);
  if (to.fullPath === '/') {
    return next({ path: '/404' });
  }
  if (to.query.wid) localStorage.setItem('xd.wid', to.query.wid);
  let wid = localStorage.getItem('xd.wid');
  if (to.meta.wid && !wid) {
    return window.location.replace(`https://tapi.xidong360.com/wxoauth?redirect=${encodeURIComponent(window.location.href)}`);
  }
  next();
});
window._doRouterPush = (path) => router.push({ path });
window._doRouterReplace = (path) => router.replace({ path });

export default router;
