import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { sync } from 'vuex-router-sync'

import utils, { sliceFile, makeUUID, makeVideoOptions, dateTime, callPhone, shareVersion } from './utils';
Vue.prototype.$utils = utils;
Vue.prototype.$sliceFile = sliceFile;
Vue.prototype.$makeUUID = makeUUID;
Vue.prototype.$makeVideoOptions = makeVideoOptions;
Vue.prototype.$dateTime = dateTime;
Vue.prototype.$callPhone = callPhone;
Vue.prototype.$shareVersion = shareVersion;
import api, { get, post, upload, plupload, } from './request'
Vue.prototype.$api = api;
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$upload = upload;
Vue.prototype.$plupload = plupload;
import async from 'async';
Vue.prototype.$async = async;
import axios from 'axios'
Vue.prototype.$axios = axios;

import moment from 'moment'
moment.locale('zh_cn');
Vue.filter('dateFormat', utils.dateTime);
Vue.filter('dateTime', utils.dateTime);
Vue.filter('classTime', function (start, end) {
  return utils.dateTime(start, "M月D日 HH:mm") + '-' + utils.dateTime(end, "HH:mm");
})
Vue.filter('join', function (arr = [], spliter = ",") {
  return arr.join(spliter);
})
import { Toast, } from "vant";
Toast.setDefaultOptions('loading', { duration: 0, forbidClick: true, loadingType: "spinner" });
Vue.use(Toast);
Vue.config.productionTip = false

sync(store, router)

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
})
