<template>
  <div class="page">
    <div class="header">
      <van-nav-bar
        title="投票结果"
        left-text="返回"
        left-arrow
        @click-left="onClickBack"
      ></van-nav-bar>
      <div class="status">
        <template v-if="voteuser">
          <img
            src="https://scdn.xidong360.com/vote2020/images/success.png"
            width="80"
            alt
          />
          <div class="info">感谢你的参与，请留下你的联系方式参与抽奖。</div>
        </template>
        <div v-if="showvoteresult && showvoteresult.type === 'xcx'">
          <img
            src="https://scdn.xidong360.com/vote2020/images/success.png"
            width="80"
            alt
          />
          <div v-html="showvoteresult.content"></div>
        </div>
      </div>
    </div>
    <div class="extra" v-if="voteuser">
      <div class="messge"></div>
      <van-panel title="温馨提示" desc="请确认所填写的信息我们能联系到你">
        <van-form>
          <van-field
            v-model="name"
            name="姓名"
            label="姓名"
            placeholder="姓名"
            :rules="[{ required: true, message: '请填写姓名' }]"
            :disabled="code === 1 || !!(voteuser && voteuser.info)"
          />
          <van-field
            v-model="phone"
            name="手机号"
            label="手机号"
            placeholder="手机号"
            :rules="[{ required: true, message: '请填写手机号码' }]"
            :disabled="code === 1 || !!(voteuser && voteuser.info)"
          />
        </van-form>
        <div slot="footer">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            :disabled="code === 1 || !!(voteuser && voteuser.info)"
            @click="onSubmit"
          >
            提交
          </van-button>
        </div>
      </van-panel>
    </div>
    <SSYFooter />
  </div>
</template>
<script>
import Vue from "vue";
import { Button, NavBar, Panel, Form, Image, Field, Dialog } from "vant";
Vue.use(Button);
Vue.use(Form);
Vue.use(NavBar);
Vue.use(Image);
Vue.use(Panel);
Vue.use(Field);
Vue.use(Dialog);
import SSYFooter from "../../common/SSYFooter";

export default {
  name: "result",
  data() {
    return {
      name: "",
      phone: "",
      code: "",
      voteuser: null,
      showvoteresult: null,
    };
  },
  components: {
    SSYFooter,
  },
  created() {
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "加载数据" });
      this.$post("/api/votedetail/getresult", { voteId: this.$route.params.id })
        .then((res) => {
          this.$toast.clear();
          console.log(res);
          this.code = res.code;
          if (res.code === 0) {
            if (res.data.voteuser) {
              this.voteuser = res.data.voteuser;
              if (res.data.voteuser.info) {
                this.name = res.data.voteuser.info.name;
                this.phone = res.data.voteuser.info.phone;
              }
            } else if (res.data.showvoteresult) {
              // this.showvoteresult = res.data.showvoteresult;
              return this.makeShare(res.data.showvoteresult);
            }
            this.makeShare();
          } else {
            this.makeShare();
            this.$toast.fail(res.msg);
          }
        })
        .catch((err) => {
          this.makeShare();
          this.$toast.clear();
          console.error(err);
        });
    },
    makeShare(showvoteresult) {
      this.$axios
        .get("https://tapi.xidong360.com/wxshare?t=" + Date.now())
        .then(({ data }) => {
          data.openTagList = ["wx-open-launch-weapp"];
          window.wx.config(data);
          window.wx.ready(() => {
            window.wx.hideAllNonBaseMenuItem();
            this.showvoteresult = showvoteresult;
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    onSubmit() {
      if (!this.name || !this.phone)
        return this.$toast.fail("姓名和手机号不能为空");
      Dialog.confirm({
        title: "提示",
        message: "请确认信息无误，无效信息将视作放弃抽奖",
      })
        .then(() => {
          this.saveinfo();
        })
        .catch(() => {
          // on cancel
        });
    },
    saveinfo() {
      this.$toast.loading({ message: "正在提交" });
      this.$post("/api/votedetail/saveinfo", {
        name: this.name,
        phone: this.phone,
        voteuserid: this.voteuser._id,
      })
        .then((res) => {
          this.$toast.clear();
          console.log(res);
          if (res.code === 0) {
            this.$toast.success("提交成功");
            this.onClickBack();
            // this.name = "";
            // this.phone = "";
            // this.getdata();
          } else {
            this.$toast.fail(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    ////返回上级
    onClickBack() {
      this.$router.go(-1);
      window.wx.showAllNonBaseMenuItem();
    },
  },
};
</script>
<style lang="less">
.status {
  text-align: center;
  padding: 30px 0 20px 0;
  .info {
    padding: 20px 10px;
  }
}
.footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  a {
    text-decoration: underline;
    margin: 0 3px;
    color: #25bf3a;
  }
}
</style>