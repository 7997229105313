<template>
  <div class="page">
    <van-image
      width="100vw"
      height="auto"
      fit="cover"
      lazy-load
      :src="game.header"
    />
    <div class="vote-content">
      <div class="vote-desc">
        <div class="vote-title">{{ game.name }}</div>
        <div class="desc">{{ game.time }}</div>
      </div>
      <div class="info">
        <van-icon name="info-o" v-on:click="showRule" />活动规则
      </div>
    </div>
    <div style="padding: 10px" v-if="game.signable">
      <van-button type="primary" block @click="gotosign">立即报名</van-button>
    </div>
    <van-tabs v-model="activeTabIndex" sticky>
      <van-tab>
        <div slot="title" @click="showtab(0)">
          <van-icon class="tab-icon" size="1.2em" name="wap-home-o" />全部
        </div>
        <div class="search">
          <van-search
            v-model="searchkey"
            background="#f2f2f2"
            show-action
            placeholder="请输入搜索关键词"
            @search="onSearch"
          >
            <div slot="action" @click="onSearch">搜索</div>
          </van-search>
        </div>
        <van-list
          v-model="voteloading"
          :finished="votefinished"
          :finished-text="votelist.length > 0 ? '没有更多了' : '暂无数据'"
          @load="onLoadVote"
        >
          <div class="vote-list">
            <waterfall :col="col" :data="votelist">
              <template>
                <div
                  class="item"
                  v-for="voteitem in votelist"
                  :key="voteitem._id"
                >
                  <van-image
                    fit="cover"
                    @click="$router.push('/votedetail/' + voteitem._id)"
                    lazy-load
                    radius="5px 5px 0 0"
                    :src="voteitem.poster || game.header"
                  />
                  <div class="item-content">
                    <div class="item-title">{{ voteitem.gname }}</div>
                    <div class="item-desc">
                      <template v-if="voteitem.workname">
                        <div class="desc">{{ voteitem.workname }}</div>
                      </template>
                      <div v-else class="desc">
                        <van-icon name="play-circle-o" />
                        {{ voteitem.playcount }}
                      </div>
                      <div class="zan">
                        <van-icon name="like" />
                        {{ voteitem.zancount }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </waterfall>
          </div>
        </van-list>
      </van-tab>
      <van-tab>
        <div slot="title" @click="showtab(1)">
          <van-icon class="tab-icon" size="1.2em" name="medal-o" />排行榜
        </div>
        <div class="rank-list">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="rankloading"
              :finished="rankfinished"
              :finished-text="ranklist.length > 0 ? '没有更多了' : '暂无数据'"
            >
              <van-cell
                v-for="(rankitem, index) in ranklist"
                :key="rankitem._id"
                :title="rankitem.gname"
                :value="rankitem.zancount > 0 ? index + 1 : '-'"
                is-link
                @click="$router.push('/votedetail/' + rankitem._id)"
                icon="medal-o"
              />
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <van-tab v-if="zhengshu">
        <div slot="title" @click="showtab(2)">🏆获奖证书</div>
        <div class="search">
          <van-search
            v-model="searchkey2"
            background="#f2f2f2"
            show-action
            placeholder="请输入搜索关键词"
            @search="onSearch2"
          >
            <div slot="action" @click="onSearch2">搜索</div>
          </van-search>
        </div>
        <div class="rank-list">
          <van-pull-refresh v-model="refreshing2" @refresh="onRefresh2">
            <van-list
              v-model="zhengshuloading"
              :finished="zhengshufinished"
              :finished-text="
                zhengshulist.length > 0 ? '没有更多了' : '暂无数据'
              "
            >
              <template v-for="zhengshuitem in zhengshulist">
                <van-cell :key="zhengshuitem.name" :title="zhengshuitem.name" />
                <van-cell
                  v-for="zhengshu_ in zhengshuitem.values"
                  :key="zhengshu_.type + zhengshu_.name"
                  :title="zhengshu_.name"
                  is-link
                  @click="
                    $router.push({
                      path: '/votezhengshu/' + zhengshu_.teamId,
                      query: { name: zhengshu_.name, type: zhengshu_.type },
                    })
                  "
                  icon="medal-o"
                />
              </template>
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
    </van-tabs>
    <van-popup
      v-model="showrulebox"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <div class="rule-container">
        <article>
          <h2>活动规则介绍</h2>
          <div v-html="game.guize"></div>
        </article>
      </div>
    </van-popup>
    <SSYFooter />
  </div>
</template>
<script>
import Vue from "vue";
import _ from "underscore";
import {
  Image,
  Lazyload,
  Cell,
  CellGroup,
  Grid,
  GridItem,
  IndexBar,
  IndexAnchor,
  Tab,
  Tabs,
  Search,
  List,
  Icon,
  Popup,
  Panel,
  Button,
  PullRefresh,
  Tag,
} from "vant";
Vue.use(Image);
Vue.use(Lazyload);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Search);
Vue.use(List);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Panel);
Vue.use(Button);
Vue.use(PullRefresh);
Vue.use(Tag);
import SSYFooter from "../../common/SSYFooter";
import waterfall from "vue-waterfall2";
Vue.use(waterfall);
export default {
  name: "VoteList",
  data() {
    return {
      game: {
        header: "",
        name: "",
        time: "",
        guize: "",
      },
      col: 2,
      activeTabIndex: 0,
      searchkey: "",
      votepage: 1,
      votelimit: 100,
      votelength: 0,
      voteloadmore: false,
      voteloading: false,
      votefinished: false,
      votelist: [],
      searchkey2: "",
      ranklist: [],
      rankloading: false,
      rankfinished: false,
      refreshing: false,
      showrulebox: false,
      refreshing2: false,
      zhengshulist: [],
      zhengshuloading: false,
      zhengshufinished: false,
      zhengshu: false,
    };
  },
  created() {
    console.log("list-created");
  },
  mounted() {
    console.log("list-mounted");
    this.searchkey = "";
    this.searchkey2 = "";
    this.getvoteinfo();
  },
  components: { SSYFooter },
  watch: {
    searchkey(searchkey) {
      if (!searchkey) {
        this.onSearch();
      }
    },
    searchkey2(searchkey) {
      if (!searchkey) {
        this.onSearch2();
      }
    },
    votepage() {
      this.getvotelist();
    },
  },
  computed: {},
  methods: {
    showtab(index) {
      this.activeTabIndex = index;
      localStorage.setItem("list.activeTabIndex", index + "");
      // 全部
      if (index === 0) {
        this.votepage = 1;
        this.getvotelist();
      }
      // 排行榜
      if (index === 1) {
        this.searchkey = "";
        this.getranklist();
      }
      if (index === 2 && this.zhengshu) {
        this.getzhengshulist();
      }
    },
    getvoteinfo() {
      this.$toast.loading({ message: "加载数据" });
      this.$post("/api/vote/getinfo", { matchId: this.$route.params.id })
        .then((res) => {
          this.$toast.clear();
          console.log(res);
          if (res.code === 0) {
            this.game = res.data.game;
            this.zhengshu = res.data.zhengshu;
            this.activeTabIndex = Number(
              localStorage.getItem("list.activeTabIndex") || "0"
            );
            this.showtab(this.activeTabIndex);
            document.title = res.data.game.name;
            this.makeShare(res.data.shareInfo);
          } else {
            this.$toast.fail(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    getvotelist() {
      this.$post("/api/vote/getlist", {
        searchkey: this.searchkey,
        matchId: this.$route.params.id,
        page: this.votepage,
        limit: this.votelimit,
      })
        .then((res) => {
          console.log(res);
          if (res.code === 0) {
            if (this.votepage === 1) this.votelist = res.data.data;
            else this.votelist.push(...res.data.data);
            this.votelength = res.data.length;
            this.voteloadmore = res.data.loadmore;
            this.votefinished = !res.data.loadmore;
            this.voteloading = false;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    onLoadVote() {
      console.log("onLoadVote");
      if (this.votepage <= this.votelength && this.voteloadmore) {
        this.votepage++;
      }
    },
    onSearch() {
      console.log("搜索");
      this.votelist = [];
      if (this.votepage > 1) this.votepage = 1;
      else this.getvotelist();
    },
    onSearch2() {
      this.getzhengshulist();
    },
    getranklist() {
      this.rankloading = true;
      this.refreshing = true;
      this.$post("/api/vote/getlist", {
        searchkey: this.searchkey,
        matchId: this.$route.params.id,
      })
        .then((res) => {
          console.log(res);
          this.rankloading = false;
          this.refreshing = false;
          this.rankfinished = true;
          if (res.code === 0) {
            let ranklist = res.data.data;
            ranklist.sort((a, b) => b.zancount - a.zancount);
            this.ranklist = ranklist;
          }
        })
        .catch((err) => {
          this.refreshing = false;
          console.error(err);
        });
    },
    onRefresh() {
      this.getranklist(true);
    },
    getzhengshulist() {
      this.zhengshuloading = true;
      this.refreshing2 = true;
      this.$post("/api/vote/getzhengshulist", {
        searchkey: this.searchkey2,
        matchId: this.$route.params.id,
      })
        .then((res) => {
          console.log(res);
          this.zhengshuloading = false;
          this.refreshing2 = false;
          this.zhengshufinished = true;
          if (res.code === 0) {
            this.zhengshulist = res.data;
          }
        })
        .catch((err) => {
          this.refreshing2 = false;
          console.error(err);
        });
    },
    onRefresh2() {
      this.getzhengshulist(true);
    },
    ////弹出展示活动规则介绍
    showRule() {
      this.showrulebox = true;
    },
    makeShare(shareInfo) {
      console.log(shareInfo);
      this.$axios
        .get("https://tapi.xidong360.com/wxshare?t=" + Date.now())
        .then(({ data }) => {
          window.wx.config(data);
          window.wx.ready(function () {
            window.wx.showAllNonBaseMenuItem();
            if (shareInfo) {
              window.wx.updateAppMessageShareData({
                ..._.pick(shareInfo, ["title", "desc", "link", "imgUrl"]),
                success: function () {},
              });
              window.wx.updateTimelineShareData({
                ..._.pick(shareInfo, ["title", "link", "imgUrl"]),
                success: function () {},
              });
            } else {
              window.wx.hideAllNonBaseMenuItem();
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    gotosign() {
      this.$router.push({ path: "/voteadd/" + this.$route.params.id });
    },
  },
};
</script>
<style lang="less" scoped>
.vote-list {
  padding: 0 5px;
  background: #f2f2f2;
  .item {
    background: #fff;
    border: 1px solid #f2f2f2;
    margin: 5px;
    .item-content {
      color: #333;
      font-size: 14px;
      padding: 5px;
      .item-desc {
        display: flex;
        color: #999;
        padding: 5px 0;
        font-size: 12px;
        .desc {
          flex: 1;
        }
        .zan {
          .van-icon {
            color: red;
          }
        }
      }
    }
  }
  .van-cell {
    padding: 5px;
  }
}
.vote-content {
  display: flex;
  color: #333;
  font-size: 14px;
  padding: 5px 10px;
  .vote-desc {
    padding: 5px 0;
    flex-grow: 1;
    .desc {
      font-size: 12px;
      color: #999;
      margin-top: 5px;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    min-width: 80px;
    .van-icon {
      font-size: 1.5rem;
    }
  }
}
.rule-container {
  padding: 10px;
  h2 {
    text-align: center;
  }
}
.van-icon {
  vertical-align: middle;
}
</style>>